import styled from '@emotion/styled';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';

export const PromotionSubBannerSkeleton = styled(Skeleton)`
  width: 20%;
  height: 80px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 48px;
  }
`;

export const PromotionBannerWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: 'calc(25% - 96px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,

    [breakpoints.down('dt_2184')]: {
      width: 'calc(33% - 96px)',
    },
    [theme.breakpoints.down('dt_1720')]: {
      width: 'calc(33% - 16px)',
    },

    [breakpoints.down('st_600')]: {
      flexShrink: 0,
      width: '100%',
      gap: '8px',
    },
    [breakpoints.up('st_600')]: {
      flexShrink: 1,
    },
    [breakpoints.up('dt_1440')]: {
      order: 1,
    },
  };
});

export const PromotionBannerContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: 6px;
  }
`;

export const PromotionSubBannerWrapper = styled.div`
  display: flex;
  gap: 8px;

  ${(props) => props.theme.breakpoints.between('dt_1440', 'dt_1720')} {
    & > ${PromotionSubBannerSkeleton} {
      width: 25%;
    }

    & > ${PromotionSubBannerSkeleton}:nth-of-type(5) {
      display: none;
    }
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: 6px;
  }
`;

export const PromotionSubBannerItem = styled.div<{ isDisplay: boolean }>`
  width: 20%;
  height: 80px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  ${(props) => !props.isDisplay && 'display: none;'}

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    width: 25%;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: 20%;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    border-radius: 12px;
    height: 48px;
  }
`;

export const PromotionSubBannerInnerBorder = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    border-radius: 12px;

    ${(props) => props.isActive && 'border: 3px solid rgba(231, 231, 232, 0.65);'}
  }

  ${(props) =>
    props.isActive &&
    `
      border: 4px solid rgba(231, 231, 232, 0.65);
  `}
`;

export const PromotionMainBannerSkeleton = styled(Skeleton)`
  height: 276px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    height: 236px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 190px;
  }
`;
