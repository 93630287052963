import styled from '@emotion/styled';
import Tags from '@components/_new_components/Common/ProductCard/Tag/Tags';
import { ProductCardDiscountRateText, ProductCardPriceWrapper, ProductCardTitleText } from '@components/_new_components/Common/ProductCard/ProductCard.styles';
import { ProductCardBrandText } from '@components/_new_components/Common/ProductCard/brand/ProductCardBrand.styles';
import PriceText from '@components/_new_components/Common/Text/PriceText';
import { AmountText, PriceUnitText } from '@components/_new_components/Common/Text/PriceText.styles';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';

export const RecentPurchasesWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: 'calc(25% - 96px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    [breakpoints.down('dt_2184')]: {
      width: 'calc(33% - 96px)',
    },

    [theme.breakpoints.down('dt_1720')]: {
      width: 'calc(33% - 16px)',
    },

    [breakpoints.down('st_600')]: {
      flexShrink: 0,
      width: '100%',
      gap: '8px',
    },
    [breakpoints.up('st_600')]: {
      flexShrink: 1,
    },
    [breakpoints.up('dt_1440')]: {
      order: 2,
    },
  };
});

export const RecentPurchaseProducts = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 0 0 1.5px #e7e7e8 inset;
  min-height: 364px;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    gap: 12px;
    min-height: 336px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    gap: 8px;
    min-height: 324px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    padding: 12px;
    border-radius: 12px;
    min-height: 244px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    min-height: 208px;
  }
`;

export const RecentProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: 16px;
  }
`;

export const RecentProductImageWrapper = styled.div`
  min-width: 156px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  ${(props) => props.theme.breakpoints.down('dt_1720')} {
    min-width: 145px;
    height: 93px;
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    min-width: 144px;
    height: 92px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    min-width: 107px;
    height: 68px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    min-width: 91px;
    height: 56px;
  }
`;

export const RecentProductImageInnerBorder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`;

export const RecentProductContents = styled.div`
  width: calc(100% - 174px);

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: calc(100% - 162px);
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    width: calc(100% - 123px);
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: calc(100% - 104px);
  }
`;

export const RecentProductTags = styled(Tags)`
  margin-top: 6px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: none;
  }
`;

export const RecentProductBrandText = styled(ProductCardBrandText)`
  color: #a0a0a0;
`;

export const RecentProductTitleText = styled(ProductCardTitleText)`
  margin-top: 3px;
  margin-bottom: 0;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 14px;
    margin-top: 0;
    font-weight: 500;
  }
`;

export const RecentProductPriceWrapper = styled(ProductCardPriceWrapper)`
  gap: 4px;
`;

export const RecentProductDiscountRateText = styled(ProductCardDiscountRateText)`
  font-size: 16px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 15px;
  }
`;

export const RecentProductPriceText = styled(PriceText)`
  & > ${PriceUnitText}, ${AmountText} {
    font-size: 16px;

    ${(props) => props.theme.breakpoints.down('st_600')} {
      font-size: 15px;
      line-height: 20px;
    }
  }

  & > ${PriceUnitText} {
    font-size: 14px;
    line-height: 25px;
    font-weight: 800;
    padding-left: 0.5px;

    ${(props) => props.theme.breakpoints.down('st_600')} {
      font-size: 13px;
      line-height: 22px;
    }
  }
`;

export const RecentProductSkeleton = styled(Skeleton)`
  height: 100px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    height: 92px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 68px;
  }
`;

export const RecentProductBrandWrapper = styled.div`
  display: flex;
  gap: 3px;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    display: none;
  }
`;
