import { Language_Code, useDisplayAssetWithItemsQuery } from 'src/generated/graphql';
import { LANGUAGE_CODE } from 'src/constants';
import { ACON_MAIN_SLIDE_AREA } from './MainWidget.constants';

export function useMainProductCardSlideQuery(areaNumber: number, languageCode: LANGUAGE_CODE) {
  const commonProductsQueryVariables = {
    language: languageCode.toUpperCase() as Language_Code,
    pagination: { page: 1, limit: 20 },
  };
  const areaType = ACON_MAIN_SLIDE_AREA[areaNumber][languageCode];
  const { data, loading, error, refetch } = useDisplayAssetWithItemsQuery({
    variables: { ...commonProductsQueryVariables, type: areaType },
    skip: !areaType,
  });

  return { data, loading, error, refetch };
}
