import { Box } from '@mui/material';
import { Skeleton } from 'carpenstreet-designsystem';

const ScrapProductSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Skeleton variant={'rectangular'} width={91} height={56} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 110px)' }}>
        <Skeleton variant={'text'} sx={{ fontSize: '14px', lineHeight: '20px' }} />
        <Skeleton variant={'text'} sx={{ typography: 'typography/body/medium/black' }} />
      </Box>
    </Box>
  );
};

export default ScrapProductSkeleton;
