import React from 'react';
import { Box } from '@mui/material';
import { Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import Link from 'next/link';
import PriceText from '@components/_new_components/Common/Text/PriceText';
import { ScrapProductPropsType } from '@components/_new_components/Pages/main/scrapProducts/ScrapProduct.types';
import { CustomImage } from '@components/ui/Image';
import { discountAmount } from '@lib/price';
import { TActionName } from '@components/common/CardGrid/type';

const ScrapProduct = ({ product, onProductClick }: ScrapProductPropsType) => {
  const theme = useDesignSystemTheme();
  const productDiscountRate = discountAmount(product.price, product.salePrice);

  const handleCardClick = (actionName: TActionName) => {
    return () => onProductClick && onProductClick(actionName, product.title, product.id, productDiscountRate);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '18px',
        alignItems: 'center',

        [theme.breakpoints.down('largeTablet')]: {
          gap: '16px',
        },
      }}
    >
      <Link href={`/product/${product.id}`} onClick={handleCardClick('image')}>
        <Box
          sx={{
            minWidth: '91px',
            height: '56px',
            position: 'relative',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <CustomImage src={product.mainImageUrl} sizes={'91px'} objectFit={'cover'} />
          <Box sx={{ position: 'absolute', width: '100%', height: '100%', borderRadius: '4px', top: 0, left: 0, border: '1px rgba(0, 0, 0, 0.04) solid' }} />
        </Box>
      </Link>

      <Box sx={{ maxWidth: 'calc(100% - 109px)' }}>
        <Link href={`/product/${product.id}`} onClick={handleCardClick('title')}>
          <Box sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 500, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}>
            {product.title}
          </Box>
        </Link>

        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {product.salePrice > 0 ? (
            <React.Fragment>
              {productDiscountRate > 0 && (
                <Typography
                  variant={'typography/body/medium/black'}
                  color={'color/state/sale'}
                  sx={{
                    [theme.breakpoints.down('smallTablet')]: {
                      fontSize: '15px',
                      lineHeight: '22px',
                    },
                  }}
                >
                  {productDiscountRate}%
                </Typography>
              )}
              <PriceText
                amount={product.salePrice}
                render={{
                  priceUnitText: (unit) => (
                    <Typography
                      variant={'typography/body/small/black'}
                      sx={{
                        [theme.breakpoints.down('smallTablet')]: {
                          fontSize: '13px',
                          lineHeight: '18px',
                        },
                      }}
                    >
                      {unit}
                    </Typography>
                  ),
                  amountText: (amount) => (
                    <Typography
                      variant={'typography/body/medium/bold'}
                      sx={{
                        [theme.breakpoints.down('smallTablet')]: {
                          fontSize: '15px',
                          lineHeight: '22px',
                        },
                      }}
                    >
                      {amount}
                    </Typography>
                  ),
                }}
              />
            </React.Fragment>
          ) : (
            <Typography
              variant={'typography/body/medium/bold'}
              sx={{
                [theme.breakpoints.down('smallTablet')]: {
                  fontSize: '15px',
                  lineHeight: '22px',
                },
              }}
            >
              FREE
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScrapProduct;
