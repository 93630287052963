import axios from 'axios';

// 유관도
export const RELATEDNESS = 3;
// storage key
export const VIEWED_PRODUCTS_LOG_KEY = 'viewed-products-log';

// 로컬스토리지에 상품을 적재하는 함수
export function trackViewedProduct(productId) {
  let products = JSON.parse(localStorage.getItem(VIEWED_PRODUCTS_LOG_KEY)) || [];

  products = products.filter((id) => id !== productId);
  products.push(productId);

  if (products.length > RELATEDNESS + 1) {
    products.shift();
  }
  console.log(`Products stored in localStorage: ${products}`);
  localStorage.setItem(VIEWED_PRODUCTS_LOG_KEY, JSON.stringify(products));
}

export const sendViewedProductsLog = async () => {
  let products = JSON.parse(localStorage.getItem(VIEWED_PRODUCTS_LOG_KEY)) || [];

  // 로그를 저장할 배열
  let accessLogs = [];

  if (products.length > 1) {
    // 마지막 3개의 상품만을 기준으로 로그를 생성 (유관도 n = 3)
    for (let i = products.length - 2; i >= Math.max(0, products.length - 1 - 3); i -= 1) {
      const step = products.length - 1 - i; // 두 상품 간의 거리 (step)
      const log = {
        start: products[i], // 시작 상품
        finish: products[products.length - 1], // 끝 상품 (가장 마지막 상품)
        step: step, // 두 상품 간의 거리
      };
      accessLogs.push(log);
    }
  }

  try {
    if (!accessLogs.length) return;

    await axios.post(`${process.env.NEXT_PUBLIC_GATEWAY_URL}/asset/view`, accessLogs, {
      withCredentials: true,
    });
    console.log('Generated Access Logs: ', accessLogs);
  } catch (err) {
    console.error(`Log loading failed (${err})`);
  }
};

export function clearViewedProductsLog() {
  if (localStorage.getItem(VIEWED_PRODUCTS_LOG_KEY)) {
    localStorage.removeItem(VIEWED_PRODUCTS_LOG_KEY);
    console.log('Viewed products log has been cleared.');
  }
}
