import { LANGUAGE_CODE } from '../../../../../src/constants';

export enum ACON_MAIN_SLIDE_AREA_ENUM {
  KO_1 = 'KO_1',
  EN_1 = 'EN_1',
  JA_1 = 'JA_1',
  RECOMMENDED = 'RECOMMENDED',
  NEW_BRAND_ASSETS = 'NEW_BRAND_ASSETS',
  MD_PICK = 'MD_PICK',
  FLASH_DEALS = 'FLASH_DEALS',
  BRAND_WEEK = 'BRAND_WEEK',
  YOU_MAY_LIKE = 'YOU_MAY_LIKE',
  NOW_SALE = 'NOW_SALE',
  CLIPSTUDIO = 'CLIPSTUDIO',
  ONLY_ON_ACON = 'ONLY_ON_ACON',
  SKETCHUP = 'SKETCHUP',
  SPECIAL_SALE = 'SPECIAL_SALE',
  FIVE_STAR = 'FIVE_STAR',
  FLASH_SALE = 'FLASH_SALE',
  FEATURED_CATEGORY = 'FEATURED_CATEGORY',
  MKT_HOW_ABOUT = 'MKT_HOW_ABOUT',
  MKT_RECOMMENDED = 'MKT_RECOMMENDED',
  SIMPLE = 'SIMPLE',
  NATURE = 'NATURE',
  DECORATION = 'DECORATION',
  MONOCHROME = 'MONOCHROME',
  BODY = 'BODY',
  MONTHLY_BEST = 'MONTHLY_BEST',
  DAILY_BEST = 'DAILY_BEST',
  ZH_BEST = 'ZH_BEST',
  ACON_ONLY = 'ACON_ONLY',
}
interface ACONMainSlideAreaMap {
  [index: number]: {
    [K in LANGUAGE_CODE]?: ACON_MAIN_SLIDE_AREA_ENUM;
  };
}

const ACON_MAIN_SLIDE_AREA_ARR = [
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.ACON_ONLY,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.EN_1,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.JA_1,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.RECOMMENDED,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.RECOMMENDED,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.NEW_BRAND_ASSETS,
    [LANGUAGE_CODE.CN]: ACON_MAIN_SLIDE_AREA_ENUM.RECOMMENDED,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.KO_1,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.RECOMMENDED,
    [LANGUAGE_CODE.CN]: ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.FLASH_DEALS,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST,
    [LANGUAGE_CODE.CN]: ACON_MAIN_SLIDE_AREA_ENUM.ZH_BEST,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.MD_PICK,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.YOU_MAY_LIKE,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.BRAND_WEEK,
    [LANGUAGE_CODE.CN]: ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.BRAND_WEEK,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.CLIPSTUDIO,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.NOW_SALE,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.SKETCHUP,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.BODY,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.SPECIAL_SALE,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.BRAND_WEEK,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.NATURE,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.MKT_RECOMMENDED,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.FIVE_STAR,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.DECORATION,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.FLASH_SALE,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.FEATURED_CATEGORY,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.MONOCHROME,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.MKT_HOW_ABOUT,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.ONLY_ON_ACON,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.CLIPSTUDIO,
  },
  {
    [LANGUAGE_CODE.KO]: ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST,
    [LANGUAGE_CODE.EN]: ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST,
    [LANGUAGE_CODE.JP]: ACON_MAIN_SLIDE_AREA_ENUM.SIMPLE,
  },
];

export const ACON_MAIN_SLIDE_AREA: ACONMainSlideAreaMap = Object.fromEntries(ACON_MAIN_SLIDE_AREA_ARR.map((data, idx) => [idx, data]));
