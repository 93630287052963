import React from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useNewResponsiveDevice } from '@hooks/useResponsiveDevice';
import PromotionMainBanner from '@components/_new_components/Pages/main/PromotionBanner/PromotionMainBanner';
import Title from '../Title';
import { PromotionBannerContents, PromotionBannerWrapper, PromotionSubBannerSkeleton, PromotionSubBannerWrapper } from './PromotionBanner.styles';

const PromotionSubBanner = dynamic(() => import('@components/_new_components/Pages/main/PromotionBanner/PromotionSubBanner'), {
  ssr: false,
  loading: () => <PromotionSubBannerSkeleton />,
});

let interval;

export default function PromotionBanner({ randomPagesOpenrun, randomPagesFlashSale }: { randomPagesOpenrun: number[]; randomPagesFlashSale: number[] }) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [isStop, setIsStop] = React.useState(false);
  const { currentBreakpoint } = useNewResponsiveDevice();

  const mergeRandomPages = [...randomPagesOpenrun, ...randomPagesFlashSale];
  const indexOfEndOfOpenrun = randomPagesOpenrun.length - 1;
  const pages = currentBreakpoint === 'dt_1440' ? mergeRandomPages.slice(0, 4) : mergeRandomPages;

  // activeIndex가 변경될 때 마다 interval 초기화
  React.useEffect(() => {
    if (isStop) return;
    if (interval) clearInterval(interval);
    interval = setInterval(() => {
      setActiveIndex((prev) => {
        const nextIndex = prev + 1;
        return nextIndex > pages.length - 1 ? 0 : nextIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex, isStop]);

  const handlePromotionSubBannerClick = (page: number) => {
    return () => setActiveIndex(page);
  };

  const handleStopBanner = () => {
    setIsStop(true);
  };

  const handleStartBanner = () => {
    setIsStop(false);
  };
  return (
    <PromotionBannerWrapper>
      <Title link="/event/sale" clickMixpanelActionName={'main discount more'}>
        {t('main:promotionBanner.title')}
      </Title>

      <PromotionBannerContents>
        {/** 메인 이미지 */}
        <PromotionMainBanner
          index={activeIndex}
          onMouseOver={handleStopBanner}
          onMouseOut={handleStartBanner}
          activePage={pages[activeIndex]}
          isOpenrun={activeIndex <= indexOfEndOfOpenrun}
        />

        {/** 서브 이미지 */}
        <PromotionSubBannerWrapper>
          {mergeRandomPages.map((page, idx) => (
            <PromotionSubBanner
              key={`${page}_${idx}`}
              page={page}
              isActive={activeIndex === idx}
              onClick={handlePromotionSubBannerClick(idx)}
              isDisplay={pages.length >= idx + 1}
              isOpenrun={idx <= indexOfEndOfOpenrun}
            />
          ))}
        </PromotionSubBannerWrapper>
      </PromotionBannerContents>
    </PromotionBannerWrapper>
  );
}
