import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Skeleton } from '@components/_new_components/Common/Skeleton/Skeleton.styles';

export const ImageAndMoreViewWrapper = styled.div`
  height: 100%;
  align-items: end;
  display: flex;
  gap: 8px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 0;
  }
`;

export const MoreViewIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 24px;
  position: relative;

  & > svg:last-child {
    display: none;
  }
`;

export const NoticesAndGuidesWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: 'calc(25% - 96px)',
    flexGrow: 1,

    [breakpoints.down('dt_1440')]: {
      flexShrink: 0,
      width: '100%',
    },
    [breakpoints.up('dt_1440')]: {
      flexShrink: 1,
      order: 3,
    },
  };
});

export const NoticesAndGuidesGridBox = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',

    [breakpoints.down('dt_2184')]: {
      width: '100%',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [breakpoints.down('st_600')]: {
      marginTop: '16px',
      gap: '16px',
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [breakpoints.between('st_600', 'lt_1024')]: {
      marginTop: '16px',
      gap: '22px',
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [breakpoints.between('lt_1024', 'dt_1440')]: {
      marginTop: '18px',
      gap: '24px 20px',
    },
    [breakpoints.between('dt_1440', 'dt_1720')]: {
      marginTop: '26px',
      gap: '21.3px',
    },
    [breakpoints.up('dt_1720')]: {
      marginTop: '28px',
      gap: '30px',
    },
  };
});

export const NoticesAndGuidesItem = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    padding: '0 28px 0 32px',
    height: '64px',
    backgroundColor: theme.palette.gray[50],
    color: theme.palette.text.primary,
    borderRadius: '99px',

    '& > p': {
      margin: 'auto auto auto 0',
      maxWidth: '200px',
    },
    '& > a': {
      margin: 'auto 0 auto 8px',
    },

    [breakpoints.down('st_600')]: {
      gap: '16px',
      fontSize: '13px',
      fontWeight: '600',
      lineHeight: '1.3',
      '& > p': {
        maxWidth: '108px',
      },
    },

    [breakpoints.up('st_600')]: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '1.3',
      letterSpacing: '-0.64px',

      '& > p > br': {
        display: 'none',
      },
    },

    [breakpoints.between('dt_1440', 'dt_1720')]: {
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '130%',
    },

    '&:hover svg:first-of-type': {
      display: 'none',
    },

    '&:hover svg:last-of-type': {
      display: 'block',
    },
  };
});

export const ImageWrapper = styled.div`
  position: relative;
  width: 132px;
  height: 80px;
  margin-bottom: 0;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: 120px;
    height: 72px;
  }
`;

export const NoticesAndGuidesSkeleton = styled(Skeleton)`
  width: 100%;
  height: 64px;
  border-radius: 64px;
`;
