import React from 'react';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { clickBannerLog } from '@lib/mixpanel/events';
import { IMiddleDetail } from '@api/banner/banner.types';
import { CustomImage } from '@components/ui/Image';
import { filterNow } from '@components/_new_components/Pages/main/banner/utils';
import Title from '../Title';
import MiddleBannerArrowIcon from '../../../../../src/assets/icons/MiddleBanner/MiddleBannerArrowIcon';
import MiddleBannerArrowHoverIcon from '../../../../../src/assets/icons/MiddleBanner/MiddleBannerArrowHoverIcon';
import {
  ImageAndMoreViewWrapper,
  ImageWrapper,
  MoreViewIconWrapper,
  NoticesAndGuidesGridBox,
  NoticesAndGuidesItem,
  NoticesAndGuidesSkeleton,
  NoticesAndGuidesWrapper,
} from './NoticesAndGuides.styles';

const Link = ({ href, children, ...props }) =>
  href ? (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  ) : (
    <span {...props}>{children}</span>
  );

export default function NoticesAndGuides({ data, isLoading }: { data: IMiddleDetail[]; isLoading: boolean }) {
  const { t } = useTranslation();
  const bannerData = filterNow(data || []).slice(0, 4);

  const handleMiddleBannerClick = (idx: number) => {
    return () => clickBannerLog({ type: 'Middle Banner', value: idx + 1 });
  };

  const handleMouseOver = (e: React.MouseEvent) => {
    const svgElements = e.currentTarget.getElementsByTagName('svg');
    if (svgElements[1]) svgElements[1].setCurrentTime(0);
  };

  return (
    <NoticesAndGuidesWrapper>
      <Title link="/event" clickMixpanelActionName={'main event more'}>
        {t('main:noticesAndGuides.title')}
      </Title>

      <NoticesAndGuidesGridBox>
        {isLoading || bannerData.length === 0
          ? Array.from({ length: 4 }, (_, idx) => <NoticesAndGuidesSkeleton key={idx} />)
          : bannerData.map(({ property: item }, idx) => {
              const textFirstLine = item?.textFirstLine ? item.textFirstLine : '';
              const textSecondLine = item?.textSecondLine ? item.textSecondLine : '';
              return (
                <Link
                  href={item.linkHref}
                  target={item?.linkHrefTarget === 'blank' ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  key={`notices_and_guides_item-${idx}: ${textFirstLine + ' ' + textSecondLine}`}
                  onClick={handleMiddleBannerClick(idx)}
                >
                  <NoticesAndGuidesItem onMouseOver={handleMouseOver}>
                    <p>
                      {textFirstLine}
                      {textSecondLine ? (
                        <>
                          <br />
                          {textSecondLine}
                        </>
                      ) : null}
                    </p>
                    <ImageAndMoreViewWrapper>
                      {item?.faceImage && (
                        <ImageWrapper>
                          <CustomImage src={item.faceImage} alt={textFirstLine + ' ' + textSecondLine} sizes={'132px'} style={{ objectFit: 'cover' }} quality={40} />
                        </ImageWrapper>
                      )}
                      <MoreViewIconWrapper>
                        <MiddleBannerArrowIcon />
                        <MiddleBannerArrowHoverIcon />
                      </MoreViewIconWrapper>
                    </ImageAndMoreViewWrapper>
                  </NoticesAndGuidesItem>
                </Link>
              );
            })}
      </NoticesAndGuidesGridBox>
    </NoticesAndGuidesWrapper>
  );
}
