import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import { CustomImage } from '@components/ui/Image';
import { discountAmount } from '@lib/price';
import { ProductCardBrandLink } from '@components/_new_components/Common/ProductCard/brand/ProductCardBrand.styles';
import { getUtcDate } from '@util/time';
import { productClickEventLog } from '@lib/mixpanel/events';
import Title from '../Title';
import {
  Brand_Output_Brand_Vip_Type,
  Language_Code,
  Ordered_Products_Input_Condition_Order_State,
  Ordered_Products_Input_Sort_Criterion,
  Ordered_Products_Input_Sort_Key,
  useOrderedProductsQuery,
} from '../../../../../src/generated/graphql';
import AconOnlyIcon from '../../../../../src/assets/icons/productCard/badge/AconOnlyIcon';
import VipIcon from '../../../../../src/assets/icons/productCard/badge/VipIcon';
import {
  RecentProductBrandWrapper,
  RecentProductContents,
  RecentProductDiscountRateText,
  RecentProductImageInnerBorder,
  RecentProductImageWrapper,
  RecentProductPriceText,
  RecentProductPriceWrapper,
  RecentProductSkeleton,
  RecentProductTags,
  RecentProductTitleText,
  RecentProductWrapper,
  RecentPurchaseProducts,
  RecentPurchasesWrapper,
  RecentProductBrandText,
} from './RecentPurchases.styles';

export default function RecentPurchases() {
  const { t, i18n } = useTranslation();
  const currentDateNow = React.useRef(
    (() => {
      const utcDate = getUtcDate();
      utcDate.setUTCSeconds(0);
      utcDate.setUTCMilliseconds(0);
      return utcDate;
    })(),
  );

  const { data, loading, error } = useOrderedProductsQuery({
    variables: {
      language: i18n.language.toUpperCase() as Language_Code,
      pagination: {
        page: 1,
        limit: 3,
      },
      condition: {
        orderStates: [Ordered_Products_Input_Condition_Order_State.Confirmed, Ordered_Products_Input_Condition_Order_State.Settled],
        isMatureContent: false,
        since: dayjs(currentDateNow.current).subtract(2, 'hour').toISOString(),
        until: currentDateNow.current.toISOString(),
        salePriceAbove: 1,
      },
      sort: {
        key: Ordered_Products_Input_Sort_Key.CreatedAt,
        criterion: Ordered_Products_Input_Sort_Criterion.Desc,
      },
    },
  });
  const products = (data?.orderedProducts?.data || []).slice(0, 3);
  const isLoading = loading || error;

  const log = React.useCallback(
    (id: number, actionName: Parameters<typeof productClickEventLog>[0]['actionName'], title: string, idx: number) => {
      productClickEventLog({
        pageName: 'main',
        actionName: 'image',
        order: idx,
        groupTitle: t('main:recentPurchases.title'),
        productNo: id.toString(),
        productName: title,
      });
    },
    [t],
  );

  return (
    <RecentPurchasesWrapper>
      <Title>{t('main:recentPurchases.title')}</Title>

      <RecentPurchaseProducts>
        {isLoading && new Array(3).fill(null).map((_, idx) => <RecentProductSkeleton key={idx} />)}

        {products.map((product, idx) => {
          const productDiscountRate = discountAmount(product.priceInfo.price, product.priceInfo.salePrice);
          return (
            <RecentProductWrapper key={idx}>
              <Link href={`/product/${product.id}`} prefetch={false} onClick={() => log(product.id, 'image', product.title, idx)}>
                <RecentProductImageWrapper>
                  <CustomImage src={product.imageInfo.main.url} alt={'recent purchases'} quality={50} sizes={'154px'} style={{ objectFit: 'cover' }} />
                  <RecentProductImageInnerBorder />
                </RecentProductImageWrapper>
              </Link>

              <RecentProductContents>
                <RecentProductBrandWrapper>
                  <ProductCardBrandLink href={`/brand/${product.brandInfo.code}`} onClick={() => log(product.id, 'brand', product.title, idx)}>
                    <RecentProductBrandText>{product.brandInfo.name}</RecentProductBrandText>
                  </ProductCardBrandLink>

                  {product.brandInfo?.isAcon3DExclusive && <AconOnlyIcon />}
                  {product.brandInfo?.vipInfo === Brand_Output_Brand_Vip_Type.Vip && <VipIcon />}
                </RecentProductBrandWrapper>

                <Link href={`/product/${product.id}`} onClick={() => log(product.id, 'title', product.title, idx)}>
                  <RecentProductTitleText>{product.title}</RecentProductTitleText>
                </Link>

                <RecentProductPriceWrapper>
                  {productDiscountRate > 0 && <RecentProductDiscountRateText>{productDiscountRate}%</RecentProductDiscountRateText>}
                  <RecentProductPriceText amount={product.priceInfo.salePrice} />
                </RecentProductPriceWrapper>

                <RecentProductTags tags={(product?.tags || []).slice(0, 2).map((tag) => tag.name)} />
              </RecentProductContents>
            </RecentProductWrapper>
          );
        })}
      </RecentPurchaseProducts>
    </RecentPurchasesWrapper>
  );
}
