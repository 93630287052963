import styled from '@emotion/styled';
import LeftAndRightButton from '@components/_new_components/Common/Button/LeftAndRightButton';

export const PopularBrandsWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    flexGrow: 1,
    order: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flexShrink: 0,
    width: '100%',

    [breakpoints.down('dt_1720')]: {
      gap: '8px',
    },
    [breakpoints.down('dt_1440')]: {
      gap: '6px',
    },
    [breakpoints.down('st_600')]: {
      gap: '4px',
    },
  };
});

export const PopularBrandsBox = styled.div`
  overflow: hidden;
  border-radius: 16px;
  display: flex;
  height: 204px;
  border: none;
  padding: 8px 16px 12px 16px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    padding: 8px 0 12px 0;
    position: relative;
    height: 196px;
    width: calc(100% + (var(--content-side-padding) * 2px));
    left: calc(var(--content-side-padding) * -1px);
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    height: 184px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    height: 170px;
  }
`;

export const Overflow = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    overflow: initial;
  }
`;

export const PopularBrandItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 33.333333%;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: auto;
  }
`;

export const PopularBrandItem = styled.div`
  min-width: 110px;
  max-width: 110px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4px;
  gap: 10px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 8px;
  }
`;

export const PopularBrandIconWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 108px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    width: 100px;
    height: 100px;
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    width: 88px;
    height: 88px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    width: 80px;
    height: 80px;
  }
`;

export const PopularBrandIconInnerBorder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.04);

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    border: 1.5px solid rgba(0, 0, 0, 0.04);
  }
`;

export const PopularBrandItemContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const PopularBrandNameText = styled.div`
  color: #212b36;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  width: 100%;
`;

export const PopularBrandInfoText = styled.div`
  text-align: center;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 105%;
  color: #ff5630ff;
  font-size: 16px;
  line-height: 24px;

  ${(props) => props.theme.breakpoints.down('st_600')} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const PopularBrandCountText = styled.div`
  color: #88888a;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const PopularSliderButtons = styled(LeftAndRightButton)`
  padding-right: 4px;

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    display: none;
  }
`;

export const PopularBrandsItems = styled.div<{ index: number }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  transition: 0.2s;
  left: 0;
  column-gap: 14px;
  border: none;
  flex-direction: row;
  ${(props) => (props.index ? `left: calc((${props.index * 110}px + calc((100% - 110px * 10) / 9) * ${props.index}) * -1);` : 'left: 0;')}
  flex-wrap: nowrap;
  gap: calc((100% - 110px * 10) / 9);

  & > ${PopularBrandItemWrapper} {
    width: auto;
    min-width: auto;
  }

  @media (max-width: 2067px) {
    gap: calc((100% - 110px * 9) / 8);
    ${(props) => (props.index ? `left: calc((${props.index * 110}px + calc((100% - 110px * 9) / 8) * ${props.index}) * -1);` : 'left: 0;')}
  }

  @media (max-width: 1893px) {
    gap: calc((100% - 110px * 8) / 7);
    ${(props) => (props.index ? `left: calc((${props.index * 110}px + calc((100% - 110px * 8) / 7) * ${props.index}) * -1);` : 'left: 0;')}
  }

  @media (max-width: 1596px) {
    gap: calc((100% - 110px * 7) / 6);
    ${(props) => (props.index ? `left: calc((${props.index * 110}px + calc((100% - 110px * 7) / 6) * ${props.index}) * -1);` : 'left: 0;')};
  }

  ${(props) => props.theme.breakpoints.down('dt_1440')} {
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 44px;
    transition: unset;
    padding-left: calc(var(--content-side-padding) * 1px);
    padding-right: calc(var(--content-side-padding) * 1px);

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    & > ${PopularBrandItemWrapper} {
      width: auto;
      min-width: auto;
    }
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: calc(100vw * 0.044);
  }

  ${(props) => props.theme.breakpoints.down('lt_1024')} {
    gap: 28px;
  }

  ${(props) => props.theme.breakpoints.down('st_600')} {
    gap: 12px;
  }
`;
