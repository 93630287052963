import React from 'react';
import Link from 'next/link';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'next-i18next';
import { buttonClickLog } from '@lib/mixpanel/events';
import { CustomImage } from '@components/ui/Image';
import Title from '../Title';
import { Brand_Output_Update_Output_Type, BrandOutputUpdateOutput, Language_Code, useDisplayBrandWithItemsQuery } from '../../../../../src/generated/graphql';
import {
  Overflow,
  PopularBrandCountText,
  PopularBrandIconInnerBorder,
  PopularBrandIconWrapper,
  PopularBrandInfoText,
  PopularBrandItem,
  PopularBrandItemContents,
  PopularBrandItemWrapper,
  PopularBrandNameText,
  PopularBrandsBox,
  PopularBrandsItems,
  PopularBrandsWrapper,
  PopularSliderButtons,
} from './PopularBrands.styles';

export default function PopularBrands() {
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const [popularBrandsTitle, setPopularBrandsTitle] = React.useState('');
  const [popularBrands, setPopularBrands] = React.useState([]);
  const { data: popularBrandsData } = useDisplayBrandWithItemsQuery({
    variables: {
      language: i18n.language.toUpperCase() as Language_Code,
      pagination: { page: 1, limit: 50 },
      type: 'POPULAR',
    },
  });

  const isUnder2560 = width < 2560;
  const isUnder2184 = width < 2184;
  const isUnder2068 = width < 2068;
  const isUnder1894 = width < 1894;
  const isUnder1597 = width < 1597;
  const isUnder1440 = width < 1440;

  const [sliderIndex, setSliderIndex] = React.useState(0);

  const maxSliderIndex = (() => {
    if (popularBrands.length === 0) return 0;
    if (!isUnder2184) return Math.ceil((popularBrands.length - (isUnder2560 ? 6 : 8)) / 2);
    if (isUnder1597) return popularBrands.length - 7;
    if (isUnder1894) return popularBrands.length - 8;
    if (isUnder2068) return popularBrands.length - 9;

    return popularBrands.length - 10;
  })();

  const handleSliderButtonClick = (type: 'prev' | 'next') => {
    return () => {
      if (type === 'prev' && sliderIndex === 0) return;
      if (type === 'next' && sliderIndex === maxSliderIndex) return;
      setSliderIndex((prev) => (type === 'prev' ? prev - 1 : prev + 1));
    };
  };

  const handleBrandItemClick = (idx: number, brandCode: string) => {
    return () => buttonClickLog({ actionName: 'main key account', value: `${idx}_${brandCode}` });
  };

  /**
    - update -> value 값은 discount, vipOrVvip type에만 존재합니다.
    - discount 타입의 경우 할인율을 리턴해주고
    - vipOrVvip 타입은 배열을 리턴하는데, 0번째 인덱스는 vvip, 1번째는 vip입니다.
   */
  const getBrandUpdateMessage = (update: BrandOutputUpdateOutput) => {
    const updateType = update?.type;
    if (Brand_Output_Update_Output_Type.Openrun === updateType) return t('main:popularBrands.status.openRun');
    if (Brand_Output_Update_Output_Type.Funding === updateType) return t('main:popularBrands.status.funding');
    if (Brand_Output_Update_Output_Type.NewProduct === updateType) return t('main:popularBrands.status.new');
    if (Brand_Output_Update_Output_Type.Discount === updateType) return t('main:popularBrands.status.discount', { discountRate: update.value });
    if (Brand_Output_Update_Output_Type.Acon3DExclusive === updateType) return t('main:popularBrands.status.aconOnly');
    if (Brand_Output_Update_Output_Type.TwinkleSale === updateType) return t('main:popularBrands.status.flashSale');
    return t('main:creator');
  };

  React.useEffect(() => {
    setSliderIndex(0);
  }, [isUnder2560, isUnder2184, isUnder2068, isUnder1894, isUnder1597, isUnder1440]);

  React.useEffect(() => {
    if (popularBrandsData?.displayBrandWithItems?.brands?.data) {
      setPopularBrandsTitle(popularBrandsData?.displayBrandWithItems?.displayInfo?.title || '');
      setPopularBrands([...popularBrandsData.displayBrandWithItems.brands.data].sort(() => Math.random() - 0.5));
    }
  }, [popularBrandsData]);
  return (
    <PopularBrandsWrapper>
      <Title
        rightComponent={
          <PopularSliderButtons
            isDisabledLeftButton={sliderIndex === 0}
            isDisabledRightButton={sliderIndex === maxSliderIndex}
            onClickLeftButton={handleSliderButtonClick('prev')}
            onClickRightButton={handleSliderButtonClick('next')}
          />
        }
      >
        {popularBrandsTitle}
      </Title>

      <PopularBrandsBox>
        <Overflow>
          <PopularBrandsItems index={sliderIndex}>
            {popularBrands.map((brand, idx) => (
              <PopularBrandItemWrapper key={brand.id}>
                <Link href={`/brand/${brand.code}`} onClick={handleBrandItemClick(idx + 1, brand.code)} prefetch={false}>
                  <PopularBrandItem>
                    <PopularBrandIconWrapper>
                      {/** 브랜드 아이콘 이미지가 존재하지 않을 경우 기본 이미지 표시 */}
                      {brand?.photoInfo?.path ? (
                        <CustomImage
                          src={brand?.photoInfo?.path}
                          alt={'brand icon'}
                          style={{ objectFit: 'cover' }}
                          sizes={'(max-width: 300px) 50vw, (max-width: 460px) 30vw, (max-width: 600px) 25vw, (max-width: 768px) 20vw, 10vw'}
                          quality={50}
                        />
                      ) : (
                        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M41.5828 24.4219L41.2754 24.2422L40.9704 24.4259L31.5287 30.1135L31.2383 30.2884V30.6274V41.4429V41.7873L31.5356 41.961L39.677 46.7173L31.1405 51.8596L30.8501 52.0345V52.3735V63.189V63.5334L31.1474 63.7071L68.0366 85.2581L68.3565 85.445L68.6669 85.2426L77.9863 79.1668L78.2586 78.9892V78.6642V68.1799V67.8356L77.9614 67.6619L69.8972 62.9475L78.3744 57.4207L78.6468 57.2431V56.9181V46.4338V46.0895L78.3496 45.9158L41.5828 24.4219ZM40.8576 47.407L68.4247 63.512L68.7447 63.6989L68.7731 63.6804L76.4887 68.191L68.3321 73.1689L32.6272 52.3649L40.8576 47.407ZM68.1274 61.9129V52.4662L32.4383 31.6714V41.0985L40.8483 46.0117L40.8872 45.9883L41.1946 46.168L68.1274 61.9129ZM33.0153 30.6188L41.2846 25.6376L76.8768 46.4449L68.7203 51.4228L33.0153 30.6188ZM69.3274 52.4581L77.4468 47.5029V56.593L69.3274 61.8865V52.4581ZM68.9392 74.2042L77.0586 69.249V78.3391L68.9392 83.6326V74.2042ZM32.0501 62.8446L67.7392 83.6946V74.2123L32.0501 53.4175V62.8446Z"
                            fill="black"
                          />
                        </svg>
                      )}

                      <PopularBrandIconInnerBorder />
                    </PopularBrandIconWrapper>
                    <PopularBrandItemContents>
                      <PopularBrandNameText>{brand.name}</PopularBrandNameText>
                      <PopularBrandInfoText>{getBrandUpdateMessage(brand?.update)}</PopularBrandInfoText>
                      <PopularBrandCountText>{t('main:popularBrands.totalCount', { count: brand.productCount })}</PopularBrandCountText>
                    </PopularBrandItemContents>
                  </PopularBrandItem>
                </Link>
              </PopularBrandItemWrapper>
            ))}
          </PopularBrandsItems>
        </Overflow>
      </PopularBrandsBox>
    </PopularBrandsWrapper>
  );
}
